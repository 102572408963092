@import "size_variables.scss";

.auth-screen, .basic-auth-screen {
  font-family: Lato !important;
  background: url('../../images/Bitmap-overlay.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
  min-height: 100vh;
  max-width: $app-container-max-width;
  width: 100vw;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  &.sign-up {
    min-height: 958px;
    .form-wrappper {
      padding: 65px 0;
    }
  }
  .form-group {
    margin-bottom: 25px;
  }
  .form-wrappper {
    position: relative;
    $form-height: 835px;
    .form{
      position: relative;
      &.bounceInDown {
        animation-duration: 0.5s;
      }
      width: 100%;
      max-width: 641px;
      min-height: $form-height;
      margin: auto;
      padding: 40px;
      padding-bottom: 100px;
      background-color: #23233A;
      color: #fff;
      border-radius: 10px;
      h4 {
        text-align: center;
      }
      .password-strength {
        border: 3px solid #383856;
        border-radius: 6px;
        height: 100%;
      }
      .footer-sign-up-form {
        border-top: 3px solid #383856
      }
      .progress {
        height: 10px;
        margin-bottom: 5px;
        .progress-bar{
          background-color: #2AD699;
        }
      }

      .country-error {
        display: none;
        margin-top: 0.25rem;
        font-size: 80%;
        color: #dc3545;
      }
    }
  }
  &.register{
    .form-wrappper {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      .form{
        margin-top: calc((100vh - 573px)/2);
        min-height: 400px !important;
      }
    }
  }
  .logo-image {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
    img {
      max-width: 200px;
      height: auto;
    }
  }
  input, select, input:focus, select:focus {
    background-color: #383856;
    color: #eee;
    border: none;
    box-shadow: none;
  }

  button {
    font-size: 18px;
    &.submit {
      margin-top: 6px;
    }
  }
  .footer-sign-up-form {
    position: absolute;
    bottom: 30px;
    width: calc(100% - 80px)
  }
}
