@import 'colors.scss';
@import 'fonts/myriad/font.scss';

@font-face {
  font-family: "Futura";
  src: url("fonts/futura/futura-book.otf") format("opentype");
  font-weight: normal;
}

@font-face {
  font-family: "Futura";
  src: url("fonts/futura/futur.otf") format("opentype");
  font-weight: bold;
}

body {
  margin: 0;
  font-family: lato, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $page-background-deep-color !important;
  user-select: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button {
  &:focus {
    box-shadow: none !important;
    outline: none !important;
  }
}

.submatrix-popover {
  z-index: 99999 !important;
  height: 250px !important;
  width: 550px !important;
  max-width: 550px !important;
  background-color: #383856 !important;

  &.bs-popover-right > .arrow::after {
    border-right-color: #383856 !important;
  }

  &.bs-popover-top > .arrow::after {
    border-top-color: #383856 !important;
  }

  &.bs-popover-left > .arrow::after {
    border-left-color: #383856 !important;
  }

  &.bs-popover-bottom > .arrow::after {
    border-bottom-color: #383856 !important;
  }
}

.__react_component_tooltip {
  max-width: 250px;
  &#ev-rescale-option-explanation, &#ev-per-eq-explanation {
    max-width: 300px;
  }
}
