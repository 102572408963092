:root {
  --primary-color: #3D64F8;
  --darker-primary-color: #001F95;
  --position-circle-border-color: #4FAEFF;
  --deck-darker-color: #001F95;
  --deck-color: #2147D8;
  --position-circle-shadow-color: #4FAEFF;
  --selected-border-color: #3D64F8;
  --selected-background-color: #3D64F8;
  --text-color: #3D64F8;
  --header-background-color: #1E1E31;
  --page-background-color: #2A2A44;
  --page-background-deep-color: #1E1E31;
  --page-background-light-color: #31314D;
  --button-background-color: #3E3E5D;
  --line-divider-color: #4C4C6C;
  --matrix-square-background-color: #616181;
  --position-circle-border-color-selected: #3D93F8;
  --button-subscribe-dark-color: #47475F;
  --button-subscribe-light-color: #47475F;
  --button-subscribe-shadow: rgba(0, 0, 0, 0.282353);
  --background-subscription-option-dark: #3D93F8;
  --background-subscription-option-light: #3D64F8;
  --special-price-color: #ffffff;
  --button-special-subscribe-dark-color: #3D93F8;
  --button-special-subscribe-light-color: #3D64F8;
  --title-subscription: #ffffff;
}

$corn-flower-blue: #474766;
$valhalla:  #2A2A44;
$old-rose: #D23650;
$primary-color: var(--primary-color);
$darker-primary-color: var(--darker-primary-color);
$deck-color: var(--deck-color);
$deck-darker-color: var(--deck-darker-color);
$border-and-bg-color-for-simdeck: #8888A8;
$position-circle-border-color: var(--position-circle-border-color);
$position-circle-border-color-selected: var(--position-circle-border-color-selected);
$position-circle-shadow-color: var(--position-circle-shadow-color);
$position-circle-background-color: #1E1E31;
$selected-border-color: var(--selected-border-color);
$selected-background-color: var(--selected-background-color);
$text-color: var(--text-color);
$header-background-color: var(--header-background-color);
$page-background-color: var(--page-background-color);
$page-background-deep-color: var(--page-background-deep-color);
$page-background-light-color: var(--page-background-light-color);
$button-background-color: var(--button-background-color);
$line-divider-color: var(--line-divider-color);
$matrix-square-background-color: var(--matrix-square-background-color);
$button-subscribe-dark-color: var(--button-subscribe-dark-color);
$button-subscribe-light-color: var(--button-subscribe-light-color);
$button-subscribe-shadow: var(--button-subscribe-shadow);
$background-subscription-option-dark: var(--background-subscription-option-dark);
$background-subscription-option-light: var(--background-subscription-option-light);
$special-price-color: var(--special-price-color);
$button-special-subscribe-dark-color: var(--button-special-subscribe-dark-color);
$button-special-subscribe-light-color: var(--button-special-subscribe-light-color);
$title-subscription: var(--title-subscription);


.primary {
  background-color: $primary-color;
}
