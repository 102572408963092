:root {
  --header-height: 75px;
  --banner-height: 120px;
}

$app-container-max-width: 1800px;
$app-content-max-width: 1500px;
$header-footer-content-padding: ($app-container-max-width - $app-content-max-width)/2;
$app-view-port-width: 96vw;
$weighted-strategy-button-max-width: 160px;

$header-height: var(--header-height);
$banner-height: var(--banner-height);
